import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import setupInterceptors from './services/setupInterceptors';
import router from './router'

import 'bootstrap/scss/bootstrap.scss'
import 'bootstrap'
import 'bootstrap-icons/font/bootstrap-icons.css'

import "@/assets/scss/custom.scss"

setupInterceptors(store)
const app = createApp(App)

app.use(router)
    .use(store)
    .mount('#app')
