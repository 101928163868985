import axiosInstance from "./api";
import {
  AUTH_LOGOUT,
  AUTH_ERROR,
} from "@/store/actions/auth";

const setup = (store) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      config.baseURL = process.env.VUE_APP_ROOT_API + '/api/v1';
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;

      if (originalConfig.url !== "/auth/login/" && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          store.dispatch(AUTH_ERROR);
          store.dispatch(AUTH_LOGOUT);
        }
      }
      return Promise.reject(err);
    }
  );
};

export default setup;