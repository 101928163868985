import { createRouter, createWebHistory } from 'vue-router'
import TokenService from "@/services/token.service";

function loadView(view) {
    return () => import(`../views/${view}.vue`);
}

const routes = [
    {
        path: '/login',
        name: 'Login',
        components: { default: loadView('LoginView') },
    },
    {
        path: "/",
        redirect: "/home",
        component: loadView('Layout'),
        children: [
            {
                path: '/home',
                name: 'Home',
                component: loadView('HomeView')
            },
            {
                path: '/message-text',
                name: 'Create Message Text',
                component: loadView('CreateMessageText'),
            },
            {
                path: '/message-text/:id',
                name: 'EditMessageText',
                component: loadView('EditMessageText'),
                props: true,
            },
            {
                path: '/message-button',
                name: 'Create Message Button',
                component: loadView('CreateMessageButton'),
            },
            {
                path: '/message-button/:id',
                name: 'EditMessageButton',
                component: loadView('EditMessageButton'),
                props: true,
            },
            {
                path: '/message-template',
                name: 'Create Message Template',
                component: loadView('CreateMessageTemplate'),
            },
            {
                path: '/message-template/:id',
                name: 'EditMessageTemplate',
                component: loadView('EditMessageTemplate'),
                props: true,
            },
            {
                path: '/survey',
                name: 'Create Survey',
                component: loadView('CreateSurvey'),
            },
            {
                path: '/survey/:id',
                name: 'EditSurvey',
                component: loadView('EditSurvey'),
                props: true,
            },
            {
                path: '/survey-report',
                name: 'SurveyReport',
                component: loadView('SurveyReport'),
            },
            {
                path: '/survey-report/:id',
                name: 'SurveyReportDetail',
                component: () => import(`../components/survey/SurveyDetail.vue`),
                props: true,
            },
            {
                path: '/import-task/:id',
                name: 'ImportResult',
                component: loadView('ImportResult'),
                props: true,
            },
            {
                path: '/import-task/',
                name: 'ImportData',
                component: loadView('ImportData'),
                props: true,
            }
        ]
    },
    {
        // will match everything
        path: '/:catchAll(.*)',
        name: 'NotFound',
        component: loadView('NotFound'),
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes: routes
})

router.beforeEach((to, from, next) => {
    const publicPages = ['/login', '/register', '/login/', '/register/'];
    const authRequired = !publicPages.includes(to.path);
    const status = TokenService.getStatus()
    const loggedIn = status ? status['loggedIn'] : undefined


    if (loggedIn && publicPages.includes(to.path)) {
        next('/')
    } else if (authRequired && !loggedIn) {
        next('/login');
    } else {
        next();
    }
});

export default router